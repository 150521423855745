.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.carousel-indicators {
  bottom: -10px; /* Adjust the vertical position as needed */
}

.carousel-indicators li {
  width: 20px; /* Adjust the size of the indicators */
  height: 10px;
  margin: 0 5px; /* Adjust the spacing between indicators */
  background-color: rgb(255, 255, 255); /* Indicator color */
  border-radius: 50%; /* Make the indicators circular */
}

.carousel-indicators .active {
  background-color: #ff00e1; /* Active indicator color */
}


.header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #e6c208;
  font-family: 'Arial', sans-serif;
  padding: 10px 20px;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;                                                                                               
}

.footer {
  background-color: #e6c208;
}


.header .welcome-message, .header .nav-menu a {
  color: #00a19b;
  font-family: 'Arial', sans-serif;
}

.cp-vacation-title {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold; 
  color: #00a19b;
  position: relative; /* Enable relative positioning for layering */
  white-space: nowrap; /* Prevents text wrapping */
  overflow: hidden; /* Hides overflow text */
  text-overflow: ellipsis; /* Adds ellipsis to overflowing text */
  /* padding: 10px;  */

  /* text-shadow: 
     -1px -1px 0px black,  
     1px -1px 0px black,   
     -1px 1px 0px black,   
     1px 1px 0px black;     */
}

.container {
  margin-top: 20px;
  align-items: center;
}

.carousel-image {
  height: 500px;
  object-fit: cover;
}

.logo {
  height: 100px; /* Adjust as necessary */
  width: auto;
}

.play-pause-button {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem; /* Adjust the font size as needed */
}

/* Styles for carousel captions */
.carousel-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(11, 137, 227); /* Semi-transparent black background */
  padding: 10px;
}

/* Styles for trip names */
.trip-name {
  font-family: 'Arial', sans-serif; /* Example of using different font */
  font-size: 2rem; /* Adjust size as needed */
  font-weight: bold;
  color: hwb(0 96% 2%); /* White color */
  margin-bottom: 0.5rem; /* Optional margin */
}

/* Styles for trip punchlines */
.trip-punchline {
  font-family: 'Arial', sans-serif; /* Example of using different font */
  font-size: 1.5rem; /* Adjust size as needed */
  font-weight: bold;
  color: #ffff00; /* Yellow color */
  margin-bottom: 0.5rem; /* Optional margin */
}

/* Styles for trip descriptions */
.trip-description {
  font-family: 'Arial', sans-serif; /* Example of using different font */
  font-size: 1.2rem; /* Adjust size as needed */
  font-weight: bold;
  color: #00ff00; /* Green color */
  margin-bottom: 0; /* Optional margin */
}

/* Styles for play/pause button */
.play-pause-button {
  font-size: 3rem; /* Larger size for button */
  color: #000000; /* Black color */
}

body {
  font-family: Arial, sans-serif;
  padding-top: 110px;
  scroll-padding-top: 70px;
}

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  height: 120px; /* Adjust the height of the navbar if needed */
}

.navbar .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
}

.navbar-nav .nav-link {
  color: #00a19b;
}

.navbar-nav .nav-link.active {
  color: #ff0000; /* Gold color for the active link */
}

.carousel {
  position: relative;
}

.carousel-image {
  height: 500px;
  object-fit: cover;
}

.carousel-caption {
  background-color: rgba(255, 255, 255, 0);
  padding: 1rem;
}

.carousel-indicators li {
  background-color: #343a40;
}

.play-pause-button {
  bottom: 10px;
  right: 10px;
}

section {
  padding: 2rem 0;
  scroll-margin-top: 56px;
  padding-top: 70px; /* Adjust according to your navbar height */
  margin-top: -70px; /* Adjust according to your navbar height */
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.social-icon {
  width: 30px;
  height: auto;
}

.menu-icon {
  display: none;
  cursor: pointer;
  font-size: 1.5rem;
  z-index: 1050; /* Higher than the header */
  position: absolute; /* Position it absolutely */
  right: 20px; /* Adjust the right position as needed */
  top: 20px; /* Adjust the top position as needed */
}

.mobile-nav {
  display: none;
  position: absolute; /* Use absolute positioning */
  top: 100%; /* Position it below the header */
  right: 0; /* Align to the right */
  background-color: #e6c208; /* Match your header color */
  text-align: left; /* Align text to the left */
  z-index: 1000;
  width: 200px; /* Set a fixed width */
  border: 1px solid #ccc; /* Optional: Add a border for clarity */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for depth */
}

.mobile-nav a {
  display: block;
  padding: 1rem;
  color: #00a19b; /* Match your link color */
  text-decoration: none;
}

.mobile-nav a:hover {
  background-color: #f1f1f1; /* Optional: Add hover effect */
}

@media (max-width: 1024px) {
  .menu-icon {
    display: block;
  }
  .navbar-nav {
    display: none; /* Hide the regular nav on smaller screens */
  }
  .mobile-nav.open {
    display: block;
  }
}

@media (max-width: 768px) {
  .navbar {
    position: fixed;
  }
  .navbar-nav {
    display: none; /* Hide the regular nav on smaller screens */
  }
  .mobile-nav.open {
    display: block;
  }
}

.justified-text {
  text-align: justify;
}

@media (max-width: 768px) {
  .header .welcome-message {
    line-height: 1;
    margin-left: 1px;
  }

  .logo {
    height: 100px; /* Adjust as necessary */
    width: auto;
  }
  .cp-vacation-title {
    font-size: 1rem; /* Adjust as necessary */
    margin-bottom: 0; /* Reduce bottom margin */
    margin-left: 0px;
    position: relative; /* Enable relative positioning for layering */
    white-space: nowrap; /* Prevents text wrapping */
    overflow: hidden; /* Hides overflow text */
    text-overflow: ellipsis; /* Adds ellipsis to overflowing text */
  }

  .lead {
    font-size: 0.8rem; /* Adjust as necessary */
    margin-top: 0; /* Reduce top margin */
    margin-left: 0px;
  }

  .navbar-brand img {
    height: 40px; /* Adjust the logo size for mobile */
  }

  .navbar-toggler {
    margin-left: auto; /* Push the toggle button to the right */
  }

  .navbar-collapse {
    justify-content: flex-end; /* Ensure menu items are aligned to the right */
  }
  .navbar-nav .nav-link {
    font-size: 0.6rem; /* Make the nav menu items smaller */
    padding: 5px 10px; /* Adjust padding for smaller size */
  }
}

/* Adjust for desktop screens */
@media (min-width: 769px) {
  .header .welcome-message {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 20px; /* Add margin to separate from the logo */
  }
}

.custom-spacing {
  margin-top: 20px; 
}

@media (max-width: 768px) {
  .custom-spacing {
    margin-top: 20px;
  }
}